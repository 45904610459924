<template>
    <div class="goods-analysis">
        <div class="goods" v-if="Object.keys(goodsData).length>0">
            <img :src="goodsData.image"/>
            <div class="goods-content">
                <span class="goods-name">{{goodsData.title}}</span>
                <div class="goods-price">
                    <p class="current-price">
                        <span><i class="iconfont">&#xe645;</i>{{goodsData.final_price}}</span>
                    </p>
                    <p class="original-price">
                        <span>原价：<i class="iconfont">&#xe645;</i>{{goodsData.max_price}}</span>
                    </p>
                </div>
                <div class="goods-like">
                    <span>抖音浏览量：{{goodsData.pv_count}}</span>
                    <span>全网销量：{{goodsData.order_count}}</span>
                </div>
            </div>
        </div>
        <div class="no-data" v-else>
            <p>该商品未关联数据</p>
        </div>
        <div class="data-trend-box">
            <div class="data-trend">
                <span class="title">抖音销量日增量</span>
                <div class="content">
                    <div class="data-menu">
                        <div class="item" :class="{selected: fanTabIndex === 0}" @click="toggleFanTab(0)">近7天</div>
                        <div class="item" :class="{selected: fanTabIndex === 1}" @click="toggleFanTab(1)">30天</div>
                    </div>
                    <div id="dyFanTrend" style="width:100%; height:442px;position: relative"></div>
                </div>
            </div>
            <div class="data-trend">
                <span class="title">抖音浏览日增量</span>
                <div class="content">
                    <div class="data-menu">
                        <div class="item" :class="{selected: browseTrendIndex === 0}" @click="toggleBrowseTrend(0)">近7天</div>
                        <div class="item" :class="{selected: browseTrendIndex === 1}" @click="toggleBrowseTrend(1)">30天</div>
                    </div>
                    <div id="dyBrowseTrend" style="width:100%; height:442px;position: relative"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VideoHeatModule",
        data() {
            return {
                fanTabIndex: 0,
                browseTrendIndex: 0,
                goodsData: {},
                // //一周内粉丝趋势数据
                // weekFansTrend: {},
                // monthFansTrend: {},
                // salesTrend: [], //粉丝趋势
                // browseTrend: [] //浏览趋势
                time: {
                    week: [], //一周的列表
                    month: [], //一个月的列表
                }, //时间对象
                orderCount: {
                    week: [], //一周的列表
                    month: [], //一个月的列表
                }, //销量对象
                pvCount: {
                    week: [], //一周的列表
                    month: [], //一个月的列表
                }, //销量总浏览量对象
            }
        },
        mounted() {
            window.onresize = () => {
                this.dyFanTrendFun();
                this.dyBrowseTrendFun();
                if (this.fanTabIndex == 0) {
                    this.dyFanTrendFun(this.time.week,this.orderCount.week);
                    this.dyBrowseTrendFun(this.time.month,this.orderCount.week);
                } else {
                    this.dyFanTrendFun(this.time.week,this.orderCount.month);
                    this.dyBrowseTrendFun(this.time.month,this.orderCount.month);
                }
            };
            let aweme_id = this.$route.query.aweme_id;
            this.hotPeopleVideoDetails(aweme_id).then(res => {
                this.goodsData = res.data.video_goods;
                let goodsData = res.data.goods_chart_data;
                for (let i in goodsData.data7) {
                    this.time.week.push(this.timestampToTime(goodsData.data7[i].update_time));
                    this.orderCount.week.push(goodsData.data7[i].order_count);
                    this.pvCount.week.push(goodsData.data7[i].pv_count)
                }
                this.dyFanTrendFun(this.time.week,this.orderCount.week);
                this.dyBrowseTrendFun(this.time.week,this.orderCount.week);
                for (let j in goodsData.data30) {
                    this.time.month.push(this.timestampToTime(goodsData.data30[j].update_time));
                    this.orderCount.month.push(goodsData.data30[j].order_count);
                    this.pvCount.month.push(goodsData.data30[j].pv_count)
                }
            }).catch(err => {
                console.error(err)
            });
            this.dyFanTrendFun(this.time.week,this.orderCount.week);
            this.dyBrowseTrendFun(this.time.week,this.orderCount.week);

        },
        methods: {
            timestampToTime(timestamp) {
                var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
                var D = date.getDate() + ' ';
                return (M + '-' + D);
            },
            hotPeopleVideoDetails(aweme_id) {
                let param = {
                    aweme_id: aweme_id
                };
                return new Promise(((resolve, reject) => {
                    this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param, res => {
                        resolve(res);
                    }, err => {
                        reject(err)
                    })
                }))
            },
            // 抖音销量日增量
            toggleFanTab(index) {
                this.fanTabIndex = index;
                if (index == 0) {
                    this.dyFanTrendFun(this.time.week,this.orderCount.week);
                } else {
                    this.dyFanTrendFun(this.time.month,this.orderCount.month);
                }
            },
            // 抖音浏览日增量
            toggleBrowseTrend(index) {
                this.browseTrendIndex = index;
                if (index == 0) {
                    this.dyBrowseTrendFun(this.time.week,this.orderCount.week);
                } else {
                    this.dyBrowseTrendFun(this.time.month,this.orderCount.month);
                }
            },
            // 抖音销量日增量
            dyFanTrendFun(timeArray,dataArray) {
                this.dyFanTrend = this.$echarts.init(document.getElementById("dyFanTrend"));
                this.dyFanTrend.setOption(
                    {
                        title: {
                            x: "center",
                            y: "bottom",
                            textStyle: {
                                //设置主标题风格
                                Color: "#333", //设置主标题字体颜色
                                fontSize: 14,
                                fontStyle: "normal"
                            }
                        },
                        xAxis: {
                            type: "category",
                            data: timeArray,
                            //设置网格线颜色
                            boundaryGap: false,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#999"
                                }
                            }
                        },
                        yAxis: {
                            type: "value",
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ["#f1f1f1"]
                                }
                            },
                            //隐藏刻度线
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#999"
                                }
                            },
                            axisLabel: {
                                fontSize: "14",
                                marginTop: "35px",
                                formatter: "{value}W",
                                show: true
                            }
                        },
                        series: [
                            {
                                data: dataArray,
                                type: "line",
                                lineStyle: {
                                    color: "#20C997",
                                    width: 3
                                },
                                areaStyle: {
                                    color: "#d8f6ec"
                                },
                                smooth: true,
                                symbol: "none"
                            }
                        ]
                    },true);
                let isZero = true;//数据是否为0
                for (let i in timeArray){
                    if(timeArray[i].length > 0){
                        isZero = false;
                        break;
                    }
                }if(isZero){
                    document.querySelector("#dyFanTrend").innerHTML = '';
                    let div1 = document.createElement("div");
                    div1.innerHTML = "暂无数据";
                    div1.style.color = "#666";
                    div1.style.position = "absolute";
                    div1.style.top = "100px";
                    div1.style.left = "50%";
                    document.querySelector("#dyFanTrend").appendChild(div1)
                }
            },
            // 抖音浏览日增量
            dyBrowseTrendFun(timeArray,dataArray) {
                this.dyBrowseTrend = this.$echarts.init(document.getElementById("dyBrowseTrend"));
                this.dyBrowseTrend.setOption(
                    {
                        title: {
                            x: "center",
                            y: "bottom",
                            textStyle: {
                                //设置主标题风格
                                Color: "#333", //设置主标题字体颜色
                                fontSize: 14,
                                fontStyle: "normal"
                            }
                        },
                        xAxis: {
                            type: "category",
                            data: timeArray,
                            //设置网格线颜色
                            boundaryGap: false,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#999"
                                }
                            }
                        },
                        yAxis: {
                            type: "value",
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ["#f1f1f1"]
                                }
                            },
                            //隐藏刻度线
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#999"
                                }
                            },
                            axisLabel: {
                                fontSize: "14",
                                marginTop: "35px",
                                formatter: "{value}W",
                                show: true
                            }
                        },
                        series: [
                            {
                                data: dataArray,
                                type: "line",
                                lineStyle: {
                                    color: "#20C997",
                                    width: 3
                                },
                                areaStyle: {
                                    color: "#d8f6ec"
                                },
                                smooth: true,
                                symbol: "none"
                            }
                        ]
                    }, true);
                let isZero = true;//数据是否为0
                for (let i in timeArray){
                    if(timeArray[i].length > 0){
                        isZero = false;
                        break;
                    }
                }
                if(isZero){
                    document.querySelector("#dyBrowseTrend").innerHTML = '';
                    let div1 = document.createElement("div");
                    div1.innerHTML = "暂无数据";
                    div1.style.color = "#666";
                    div1.style.position = "absolute";
                    div1.style.top = "100px";
                    div1.style.left = "50%";
                    document.querySelector("#dyBrowseTrend").appendChild(div1)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-analysis {
        display: flex;
        flex-direction: column;

        .goods {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 0 20px;

            img {
                width: 110px;
                height: 158px;
                margin-right: 20px;
            }

            .goods-content {
                .goods-name {
                    display: block;
                    font-size: 18px;
                    font-weight: bold;
                    color: #333;
                    margin-bottom: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: inherit;
                }

                .goods-price {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 10px;

                    .current-price {
                        font-size: 16px;
                        font-weight: bold;
                        color: #FF0000;
                        margin-right: 20px;

                        i {
                            font-size: 14px;
                        }
                    }

                    .original-price {
                        font-size: 14px;
                        font-weight: 400;
                        text-decoration: line-through;
                        color: #666;

                        i {
                            font-size: 14px;
                        }
                    }
                }

                .goods-like {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999;

                    span:first-child {
                        margin-right: 40px;
                    }
                }
            }
        }
        .data-trend {
            padding: 20px;
        }

        .data-menu {
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            font-weight: 400;
            color: #999;
            text-align: right;

            .item {
                display: inline-block;
                padding: 0 10px;
                border-right: 1px solid #999;
                cursor: pointer;

                &:hover {
                    color: #20C997;
                }

                &:last-of-type {
                    border-right: 0;
                    padding: 0 0 0 10px;
                }
            }

            .selected {
                color: #20C997;
            }
        }

        .class-list {
            height: 1%;
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .no-data {
            display: flex;
            justify-content: center;
        }
    }
</style>