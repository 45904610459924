<template>
    <!--视频详情-->
    <div class="video-detail">
        <div class="video-chart" v-if="Object.keys(videoDetail).length > 0">
            <div class="header-info">
                <div class="avatar">
                    <img :src="videoDetail.aweme_cover" alt="" >
                    <div class="info">
                        <span class="video-title">{{videoDetail.aweme_title}}</span>
                        <span class="fans">{{videoDetail.follower_count}}</span>
                    </div>
                </div>
                <div class="video-info">
                    <ul class="like-item">
                        <li>
                            <span>总点赞</span>
                            <span>{{videoDetail.digg_count}}</span>
                        </li>
                        <li>
                            <span>总评论</span>
                            <span >{{videoDetail.comment_count}}</span>
                        </li>
                        <li>
                            <span>总转发</span>
                            <span>{{videoDetail.share_count}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else class="video-chart-null">
            <p>该商品未关联数据</p>
        </div>
        <div class="video-comment">
            <span class="title">热门评论<span class="num">（{{commentList.length}}）</span></span>
                <ul class="comment-ul" v-if="commentList.length>0">
                    <li v-for="(item,index) in commentList">
                        <div class="content">
                            <span class="text">{{item.text}}</span>
                            <div class="comment-text">
                                <div class="text-left">
                                    <span class="time">{{item.create_time}}</span>
                                </div>
                                <div class="like-num">
                                    <i class="iconfont">&#xe85c;</i>
                                    <span class="num">{{item.digg_count}}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul v-else class="comment-ul-null">
                    <p>该商品未关联数据</p>
                </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VideoDetailModule",
        data() {
            return{
                commentList:[],//
                videoDetail:{},
            }
        },
        mounted() {
            // 获取视频详情
            let aweme_id = this.$route.query.aweme_id;
            let param = {
                aweme_id:aweme_id
            };
            this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param,res => {
                if (res.code == 200) {
                    let obj = {};
                    obj['aweme_title'] = res.data.video_details.aweme.aweme_title;
                    obj['aweme_cover'] = res.data.video_details.aweme.aweme_cover;
                    obj['follower_count'] = res.data.video_details.author.follower_count;
                    obj['digg_count'] = res.data.video_details.aweme.digg_count;
                    obj['share_count'] = res.data.video_details.aweme.share_count;
                    obj['comment_count'] = res.data.video_details.aweme.comment_count;
                    this.videoDetail = obj;
                    this.commentList = res.data.video_comments;

                }else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
.video-detail{
    display: flex;
    flex-direction: column;
    padding: 0 25px 25px;
    .video-chart{
        height: 100%;
        overflow: auto;
        flex: 1;
        padding-right: 10px;
        .header-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .avatar {
                display: flex;
                justify-content: start;
                img {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                    border-radius: 50%;
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51,51,51,1);
                    .text{
                        color: #333;
                        font-size:14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        white-space: inherit;
                        line-height: 1.5;
                    }
                }
            }
            .video-info{
                .like-item {
                    list-style-type: none;
                    display: flex;
                    justify-content: start;
                    padding: 0;
                    margin: 0;
                    li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0 20px;
                        border-right: 1px solid #EAEAEA;
                        &:last-child {
                            border-right: none;
                        }
                        span{
                            line-height:1.5;
                        }

                    }
                }
            }
        }
    }
    .video-comment {
        display: flex;
        flex-direction: column;
        .title {
            font-size:14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            padding: 20px 0;
            .num {
                color: #aaaaaf;
            }
        }
        .comment-ul{
            flex: 1;
            padding: 0 8px 0 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                display: flex;
                justify-content: start;
                margin-bottom: 15px;
                margin-left: 10px;
                .content {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height:2;
                    .comment-text {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #EAEAEA;
                        padding-bottom: 10px;
                        .like-num {
                            display: flex;
                            align-items: center;
                            i {
                                color: #999;
                                margin-right: 5px;
                            }
                        }

                    }
                    .time{
                        color: #999;
                        font-size:14px;
                    }
                }
            }
        }
    }
    /*暂无数据*/
    .comment-ul-null{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 418px;
    }
    .video-chart-null{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>