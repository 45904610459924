<template>
    <div class="goods-analysis">
        <div class="goods" v-if="Object.keys(goodsData).length>0">
            <img :src="goodsData.image" @click="goodsDdtails(goodsData.url)"/>
            <div class="goods-content">
                <span class="goods-name" @click="goodsDdtails(goodsData.url)">{{goodsData.title}}</span>
                <div class="goods-price">
                    <p class="current-price">
                        <span><i class="iconfont">&#xe645;</i>{{goodsData.final_price}}</span>
                    </p>
                    <p class="original-price">
                        <span>原价：<i class="iconfont">&#xe645;</i>{{goodsData.max_price}}</span>
                    </p>
                </div>
                <div class="goods-like">
                    <span>抖音浏览量：{{goodsData.pv_count}}</span>
                    <span>全网销量：{{goodsData.order_count}}</span>
                </div>
            </div>
        </div>
        <div class="no-data" v-else>
            <p>该商品未关联数据</p>
        </div>
        <div class="goods-promotion">
            <a class="title">相关商品推广视频</a>
            <div class="promotion-list" v-if="goodsList.length > 0">
                <div class="promotion-item" v-for="(item,index) in goodsList">
                    <div class="goods-info" @click="goodsVideoDetails(item.aweme_info.aweme_url)">
                        <img :src="item.aweme_info.aweme_cover" alt="">
                        <span class="goods-name" >{{item.aweme_info.aweme_title}}</span>
                    </div>
                    <div class="bozhu-info">
                        <img :src="item.author_info.avatar" alt/>
                        <p>
                            <span class="bozhu-name" >{{item.author_info.nickname}}</span>
                            <span class="time" >{{item.aweme_info.aweme_create_time}}</span>
                        </p>
                    </div>
                    <div class="like" >
                        <span class="span-hover"> <i class="iconfont">&#xe62a;</i>{{item.aweme_info.digg_count}}</span>
                        <span class="span-hover"><i class="iconfont">&#xe6e7;</i>{{item.aweme_info.download_count}}</span>
                        <span class="span-hover"><i class="iconfont">&#xe664;</i>{{item.aweme_info.comment_count}}</span>
                    </div>
                </div>
            </div>
            <div class="no-data" v-else>
                <p class="video-p">该商品未关联数据</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VideoGoodsAnalysisModule",
        data() {
            return {
                goodsData: {},
                goodsList: []
            }
        },
        mounted() {
            let aweme_id = this.$route.query.aweme_id;
            let param = {
                aweme_id: aweme_id
            };
            this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param, res => {
                if (res.code == 200) {
                    this.goodsData = res.data.video_goods;
                    this.goodsList = res.data.goods_video_list;
                    // 所有带货视频列表
                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });
        },
        methods:{
            // 商品详情
            goodsDdtails(url){
                window.open(url)
            },
            // 视频
            goodsVideoDetails(aweme_url){
                window.open(aweme_url)
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-analysis {
        display: flex;
        flex-direction: column;

        .goods {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 0 20px;

            img {
                width: 110px;
                height: 158px;
                margin-right: 20px;
                cursor: pointer;
            }

            .goods-content {
                .goods-name {
                    display: block;
                    font-size: 18px;
                    font-weight: bold;
                    color: #333;
                    margin-bottom: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: inherit;
                    cursor: pointer;
                    &:hover{
                        color: #2338E6;
                    }
                }

                .goods-price {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 10px;

                    .current-price {
                        font-size: 16px;
                        font-weight: bold;
                        color: #FF0000;
                        margin-right: 20px;

                        i {
                            font-size: 14px;
                        }
                    }

                    .original-price {
                        font-size: 14px;
                        font-weight: 400;
                        text-decoration: line-through;
                        color: #666;

                        i {
                            font-size: 14px;
                        }
                    }
                }

                .goods-like {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999;

                    span:first-child {
                        margin-right: 40px;
                    }
                }
            }
        }

        .goods-promotion {
            margin-top: 20px;

            .title {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                display: block;
                height: 55px;
                line-height: 55px;
                border-bottom: 1px solid #EAEAEA;
                border-top: 1px solid #EAEAEA;
                margin: 0 20px;
            }

            .promotion-list {
                margin: 0;
                padding: 20px;
                list-style-type: none;

                .promotion-item {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #EAEAEA;

                    .goods-info {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 50%;
                        cursor: pointer;
                        img {
                            width: 61px;
                            height: 88px;
                            margin-right: 20px;
                        }

                        .goods-name {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: rgba(51, 51, 51, 1);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            white-space: inherit;
                        }
                    }

                    .bozhu-info {
                        display: flex;
                        justify-content: start;
                        align-items: center;

                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }

                        p {
                            display: flex;
                            flex-direction: column;

                            .bozhu-name {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: rgba(51, 51, 51, 1);
                            }

                            .time {
                                font-size: 14px;
                                color: #999;
                                line-height: 2;
                            }

                        }
                    }

                    .like {
                        display: flex;
                        align-items: center;
                        min-width: 200px;
                        .span-hover {
                            display: flex;
                            align-items: center;
                            padding: 10px 20px 0 0;
                            color: #999;

                            i {
                                font-size: 18px;
                                color: #999;
                                padding: 0 6px 0 0;
                            }
                        }
                    }
                }
            }
        }

        .no-data {
            display: flex;
            justify-content: center;
            .video-p{
                min-height:418px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>